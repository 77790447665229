/**
 * By default, Remix will handle hydrating your app on the client for you.
 * You are free to delete this file if you'd like to, but if you ever want it revealed again, you can run `npx remix reveal` ✨
 * For more information, see https://remix.run/file-conventions/entry.client
 */

import { CacheProvider } from "@emotion/react";
import { RemixBrowser } from "@remix-run/react";
import { startTransition, StrictMode, useState } from "react";
import { hydrate } from "react-dom";
import createEmotionCache, {
  defaultCache,
} from "./chakra-ui/createEmotionCache";
import { ClientStyleContext } from "./chakra-ui/context";
import { inspect } from "@xstate/inspect";

const clientUrl = new URL(window.location.href);
const XDEBUG = clientUrl.searchParams.get("XDEBUG") === "machine";
if (XDEBUG && process.env.NODE_ENV != "production") {
  inspect({ iframe: false });
}

interface ClientCacheProviderProps {
  children: React.ReactNode;
}

function ClientCacheProvider({ children }: ClientCacheProviderProps) {
  const [cache, setCache] = useState(defaultCache);

  function reset() {
    setCache(createEmotionCache());
  }

  return (
    <ClientStyleContext.Provider value={{ reset }}>
      <CacheProvider value={cache}>{children}</CacheProvider>
    </ClientStyleContext.Provider>
  );
}

const doHydrate = () => {
  startTransition(() => {
    // XXX workaround for hydration js errors on react 18.2 - ref https://github.com/remix-run/remix/issues/2570#issuecomment-1257234517
    // Note `hydrate` is deprecated - ref https://react.dev/reference/react-dom/hydrate :
    // > In React 18, hydrate was replaced by hydrateRoot. Using hydrate in React 18 will warn that your app will behave as if it’s running React 17.
    // Alternative workaround: https://github.com/Xiphe/remix-island .
    // TODO PRE-853 Go back to using `hydrateRoot` if hopefully hydration errors are mitigated in react 18.3+. Else, apply alternative workaround (see above).
    hydrate(
      <ClientCacheProvider>
        <StrictMode>
          <RemixBrowser />
        </StrictMode>
      </ClientCacheProvider>,
      document,
    );
  });
};

if (typeof requestIdleCallback === "function") {
  requestIdleCallback(doHydrate);
} else {
  // Safari doesn't support requestIdleCallback
  // https://caniuse.com/requestidlecallback
  setTimeout(doHydrate, 1);
}
