import createCache from "@emotion/cache";

export const defaultCache = createEmotionCache();

export default function createEmotionCache() {
  return createCache({
    key: "contentsecuritypolicy",
    nonce: "nonce-051be3e3866e7235116a906ee545b71b",
  });
}
